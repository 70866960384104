import React, { PureComponent } from 'react'
import Equalizer from './Equalizer'
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import classnames from 'classnames'

export const query = graphql`
  fragment HomeFocusAreaImage on File {
    childImageSharp {
      fluid(maxWidth: 512, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

class HomeFocusAreas extends PureComponent {
  constructor(props) {
    super(props)
    if (typeof window !== 'undefined') {
      this.media = window.matchMedia('screen and (min-width: 900px)')
    }
  }

  getHeaderNodes() {
    return [this.headerNode0, this.headerNode1, this.headerNode2]
  }

  getDescriptionNodes() {
    return [this.descriptionNode0, this.descriptionNode1, this.descriptionNode2]
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            column1Image: file(relativePath: { eq: "column-1@2x.jpg" }) {
              ...HomeFocusAreaImage
            }
            column2Image: file(relativePath: { eq: "column-2@2x.jpg" }) {
              ...HomeFocusAreaImage
            }
            column3Image: file(relativePath: { eq: "column-3-new.png" }) {
              ...HomeFocusAreaImage
            }
          }
        `}
        render={data => (
          <Equalizer
            byRow={false}
            nodes={() => this.getHeaderNodes()}
            enabled={() => (this.media ? this.media.matches : true)}
          >
            <Equalizer
              byRow={false}
              nodes={() => this.getDescriptionNodes()}
              enabled={() => (this.media ? this.media.matches : true)}
            >
              {this.props.isHomepage && (
                <h2 className="text-blue-dianne font-normal font-serif text-center text-xl lg:text-2xl mt-12 lg:mt-24">
                  Our Focus Areas
                </h2>
              )}
              <div
                className={classnames(
                  'lg:flex justify-between font-serif text-blue-dianne',
                  {
                    'my-32': !this.props.isHomepage,
                    'mt-8 lg:mt-16 mb-32': this.props.isHomepage,
                  }
                )}
              >
                <div className="lg:w-1/3 lg:mr-8 mb-12 lg:mb-0 relative">
                  <div className="bg-selago bg-home-age-science! bg-center bg-cover relative overflow-hidden">
                    <Img
                      fluid={data.column1Image.childImageSharp.fluid}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <Link
                      to="/focus-areas/age-science"
                      className="flex justify-center px-8 py-32 text-blue-dianne no-underline relative"
                    >
                      <h2 className="text-2xl font-normal">
                        {this.props.column1 ? (
                          <React.Fragment>
                            {this.props.column1.header.split(' ')[0]}
                            <br />
                            {this.props.column1.header.split(' ')[1]}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Age
                            <br />
                            Science
                          </React.Fragment>
                        )}
                      </h2>
                    </Link>
                  </div>
                  <div className="bg-white relative lg:mx-12 px-8 py-8 lg:-mt-24">
                    <h3
                      className="text-base font-normal mb-4"
                      ref={node => (this.headerNode0 = node)}
                    >
                      {this.props.column1.title}
                    </h3>
                    <p
                      className="font-sans leading-normal"
                      ref={node => (this.descriptionNode0 = node)}
                    >
                      {this.props.column1.text}
                    </p>
                    <div className="flex flex-wrap font-sans mt-6 text-gothic text-sm">
                      <Link
                        to="/focus-areas/age-science"
                        className="block no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Learn more
                      </Link>
                      <span className="mx-4 text-gothic opacity-50 mt-2">
                        |
                      </span>
                      <Link
                        to="/insights/age-science"
                        className="block no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Show Insights
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/3 lg:mr-8 mb-12 lg:mb-0 relative">
                  <div className="bg-selago bg-home-preventive-healthcare! bg-center bg-cover relative overflow-hidden">
                    <Img
                      fluid={data.column2Image.childImageSharp.fluid}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <Link
                      to="/focus-areas/preventive-healthcare"
                      className="flex justify-center px-8 py-32 text-blue-dianne no-underline relative"
                    >
                      <h2 className="text-2xl font-normal">
                        {this.props.column2 ? (
                          <React.Fragment>
                            {this.props.column2.header.split(' ')[0]}
                            <br />
                            {this.props.column2.header.split(' ')[1]}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Preventive
                            <br />
                            Healthcare
                          </React.Fragment>
                        )}
                      </h2>
                    </Link>
                  </div>
                  <div className="bg-white lg:mx-12 px-8 py-8 lg:-mt-24 relative">
                    <h3
                      className="text-base font-normal mb-4"
                      ref={node => (this.headerNode1 = node)}
                    >
                      {this.props.column2.title}
                    </h3>
                    <p
                      className="font-sans leading-normal"
                      ref={node => (this.descriptionNode1 = node)}
                    >
                      {this.props.column2.text}
                    </p>
                    <div className="flex flex-wrap mt-6 font-sans text-gothic text-sm">
                      <Link
                        to="/focus-areas/preventive-healthcare"
                        className="no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Learn more
                      </Link>
                      <span className="mx-4 text-gothic opacity-50 mt-2">
                        |
                      </span>
                      <Link
                        to="/insights/preventive-healthcare"
                        className="no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Show Insights
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/3 relative">
                  <div className="bg-selago bg-home-economy! bg-center bg-cover relative overflow-hidden">
                    <Img
                      fluid={data.column3Image.childImageSharp.fluid}
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        right: 0,
                        width: '100%',
                        height: '100%',
                      }}
                    />
                    <Link
                      to="/focus-areas/longevity-economy"
                      className="flex justify-center px-8 py-32 text-blue-dianne no-underline relative"
                    >
                      <h2 className="text-2xl font-normal">
                        {this.props.column3 ? (
                          <React.Fragment>
                            {this.props.column3.header.split(' ')[0]}
                            <br />
                            {this.props.column3.header.split(' ')[1]}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            Longevity
                            <br />
                            Economy
                          </React.Fragment>
                        )}
                      </h2>
                    </Link>
                  </div>
                  <div className="bg-white lg:mx-12 px-8 py-8 lg:-mt-24 relative">
                    <h3
                      className="text-base font-normal mb-4"
                      ref={node => (this.headerNode2 = node)}
                    >
                      {this.props.column3.title}
                    </h3>
                    <p
                      className="font-sans leading-normal"
                      ref={node => (this.descriptionNode2 = node)}
                    >
                      {this.props.column3.text}
                    </p>
                    <div className="flex flex-wrap mt-6 font-sans text-gothic text-sm">
                      <Link
                        to="/focus-areas/longevity-economy"
                        className="no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Learn more
                      </Link>
                      <span className="mx-4 text-gothic opacity-50 mt-2">
                        |
                      </span>
                      <Link
                        to="/insights/longevity-economy"
                        className="no-underline hover:underline text-gothic uppercase mt-2"
                      >
                        Show Insights
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Equalizer>
          </Equalizer>
        )}
      />
    )
  }
}

export default HomeFocusAreas
