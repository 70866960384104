import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic-graphql'
import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeFocusAreas from '../components/HomeFocusAreas'
import PageHeader from '../components/PageHeader'

const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            column_1_header
            column_1_title
            column_1_text
            column_1_link {
              _linkType
              ...Link
            }
            column_2_header
            column_2_title
            column_2_text
            column_2_link {
              _linkType
              ...Link
            }
            column_3_header
            column_3_title
            column_3_text
            column_3_link {
              _linkType
              ...Link
            }
          }
        }
      }
    }
  }
`

const linkFragment = graphql`
  fragment Link on PRISMIC_Focus_area_page {
    _meta {
      uid
    }
  }
`

export default class FocusAreasPage extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={query}
        render={withPreview(
          ({ prismic: { allHomepages: homepageData } }) => {
            const column1 = {
              header: homepageData.edges[0].node.column_1_header,
              title: homepageData.edges[0].node.column_1_title,
              text: homepageData.edges[0].node.column_1_text,
            }
            const column2 = {
              header: homepageData.edges[0].node.column_2_header,
              title: homepageData.edges[0].node.column_2_title,
              text: homepageData.edges[0].node.column_2_text,
            }
            const column3 = {
              header: homepageData.edges[0].node.column_3_header,
              title: homepageData.edges[0].node.column_3_title,
              text: homepageData.edges[0].node.column_3_text,
            }

            return (
              <Layout>
                <SEO title="Longevity Network" keywords={[`longevity`]} />

                <PageHeader titles={['Focus Areas']} />

                <div className="max-w-5xl mx-auto">
                  <HomeFocusAreas
                    column1={column1}
                    column2={column2}
                    column3={column3}
                  />
                </div>
              </Layout>
            )
          },
          query,
          [linkFragment]
        )}
      />
    )
  }
}
